document.addEventListener("DOMContentLoaded", function() {
    const burger = document.querySelector(".js-burger");
    const nav = document.querySelector(".nav-mobile");
    const navCloseList = document.querySelectorAll(".js-nav-mobile-close");
    navCloseList.forEach(navClose => {
        navClose.addEventListener("click", function() {
            nav.classList.remove("open");
        })
    })
    burger.addEventListener("click", function() {
        nav.classList.toggle("open");
    })
})